import "@popperjs/core/dist/umd/popper.min.js"
/* eslint-disable */
import * as bootstrap from "bootstrap"

//import "./node_modules/@fontsource/roboto"
import "./src/scss/all.scss"

// Flashing icons
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false
